.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__header {
    background-color: white;
}

.react-datepicker__day--selected {
    // circle
    border-radius: 50%;
    background-color: #818cf8;
}

.react-datepicker__day:hover {
    border-radius: 50%;
    background-color: #c7d2fe;
}

.react-datepicker {
    font-family: inherit !important;
}

.react-datepicker__day--outside-month {
    opacity: 0.3;
}
