.chevIcon {
    transition: all 0.2s;
}

.chevIcon.rotate {
    transform: rotate(90deg);
}

.subMenu {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.3s;
}

.subMenu.open {
    opacity: 1;
    visibility: visible;
    max-height: 100px;
}
