.main-container {
    display: flex;
}

.main-content {
    position: relative;
    overflow-x: auto;
    width: 100%;
    height: 100vh;
    // background-color: #f2f2f2;
}

@media only screen and (max-width: 600px) {
    .main-content {
        padding-top: 50px;
        width: 100%;
        height: 100vh;
        position: absolute;
        // max-height: calc(100%);
        // max-height: 100vh;
        // background-color: #f9f9f9;
    }
}